import image1 from "./ImagesGallery/1.png"
import image2 from "./ImagesGallery/2.png"
import image3 from "./ImagesGallery/3.jpg"
import image4 from "./ImagesGallery/4.jpg"
import image5 from "./ImagesGallery/5.jpg"
import image6 from "./ImagesGallery/6.jpg"
import image7 from "./ImagesGallery/7.jpg"
import image8 from "./ImagesGallery/8.png"
import image9 from "./ImagesGallery/8.png"

export const IMAGES1 = [
    {
        img:image1
    },{
        img:image2
    },{
        img:image3
    },{
        img:image4
    },{
        img:image5
    },{
        img:image6
    },{
        img:image7
    },{
        img:image8
    },{
        img:image9
    }
]
