import React from 'react'
import { useParams } from 'react-router-dom';

export default function ResetPassword() {
  const { token } = useParams();

    const OnchangePassword = () =>{
        
    } 

  return (
    <div>

    </div>
  )
}
